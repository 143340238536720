import Vue from 'vue';
import VueFormulate from '@braid/vue-formulate';
import { en, nl, fr } from '@braid/vue-formulate-i18n';
import i18n from '@/i18n';

const apiErrorHandler = function(error) {
  const status = error?.response?.status,
    code = error?.response?.data?.code,
    detail = error?.response?.data?.detail,
    genericErr = `${status ? status + ':' : ''} ${i18n.t(
      'something_went_wrong'
    )}`;

  const errorData = {
      inputErrors: error?.inputErrors || error?.response?.data,
      formErrors: error?.formErrors || []
    },
    finalError =
      status === 500
        ? i18n.t('internal_server_error')
        : code
        ? i18n.t(code)
        : detail || genericErr;

  errorData.formErrors.push(finalError);

  return errorData;
};

const options = {
  plugins: [en, nl, fr],
  errorHandler: apiErrorHandler,

  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  rules: {
    phone: ({ value }) =>
      !!value.match(
        /^\s*(?:\+?(\d{1,3}))?[ (]*(\d{3,4})[-. )]*(\d{3,4})[-. ]*(\d{3,4})\s*$/
      ),
    atleastOneSpecialCharacterAndNumber: ({ value }) => {
      // eslint-disable-next-line no-useless-escape
      const hasSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const hasNumber = /[0-9]/;
      return Promise.resolve(
        hasSpecialChar.test(value) && hasNumber.test(value)
      );
    },
    notEmail: ({ value }) => {
      // eslint-disable-next-line
      const isEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      return Promise.resolve(!isEmail.test(value));
    }
  },

  locale: '',

  library: {
    autocomplete: {
      classification: 'text',
      component: 'BaseInputAutoComplete',
      slotProps: {
        component: ['autoCompleteType', 'multiple']
      }
    },
    password: {
      classification: 'text',
      component: 'BaseInputPassword',
      slotProps: {
        component: ['showPasswordButton']
      }
    },
    multiselect: {
      classification: 'select',
      component: 'BaseInputMultipleSelect'
    },
    toggle: {
      classification: 'box',
      component: 'BaseInputToggle'
    }
  },

  slotComponents: {
    label: 'BaseInputLabel'
  },

  slotProps: {
    label: ['showRequiredLabel', 'showOptionalLabel']
  },

  classes: {
    outer: 'my-3.75',
    wrapper({ classification }) {
      const styles = {
        toggle: 'flex items-center gap-2'
      };
      // group is used to change the styling of the child "label"
      return styles[classification] || 'relative group';
    },
    element({ classification }) {
      const styles = {
        box: 'w-auto inline-block'
      };

      return styles[classification];
    },
    input({ classification }) {
      const styles = {
        text: 'formulate-text',
        textarea: 'formulate-textarea',
        select: 'formulate-select',
        button: 'formulate-button tg-button-small-bold md:tg-button-bold'
      };

      return styles[classification];
    },
    label({ classification }) {
      const styles = {
        box: 'tg-form-input-value-default text-grey-2',
        toggle: 'tg-body-small font-semibold text-grey-5',
        group: 'font-bold text-sm'
      };
      return styles[classification] || 'floating-label tg-form-label';
    },
    help: 'formulate-help',
    error: 'input-error whitespace-pre-wrap' // Whitespace in the input shows as a single whitespace if we don't use whitespace-pre
  }
};

export function setupFormulate() {
  Vue.use(VueFormulate, options);
}
