import axios from 'axios';

// Client for Mapbox service
const mapboxClient = axios.create({
  baseURL: process.env.VUE_APP_MAPBOX_API_URL,
  params: {
    access_token: process.env.VUE_APP_MAPBOX_API_KEY
  }
});

// Mapbox Address search
export function mapboxAddressSearch({ params }) {
  const { query, ...otherParams } = params;
  return mapboxClient.get('/search/geocode/v6/forward', {
    params: {
      q: query,
      proximity: 'ip',
      types: 'address,street',
      language: 'nl,fr,en',
      limit: 10, // Max limit
      ...otherParams
    }
  });
}
