<template>
  <!-- Toggle Button -->
  <label
    :for="context.id"
    :data-type="context.type"
    :class="['my-auto cursor-pointer', context.classes.element]"
  >
    <!-- toggle -->
    <div class="relative">
      <!-- input -->
      <input
        v-model="context.model"
        type="checkbox"
        v-bind="context.attributes"
        class="sr-only absolute border-none cursor-pointer"
        @blur="context.blurHandler"
      />
      <!-- line -->
      <div class="toggle__line">
        <span
          class="tg-tag-small mx-2.5 text-grey-2 transition-all duration-300 ease-in-out"
          :class="{ 'text-grey-5': context.model }"
        >
          {{ context.model ? $t('on') : $t('off') }}
        </span>
      </div>
      <!-- dot -->
      <div
        class="toggle__dot absolute w-3 h-3 m-1.5 bg-grey-2 my-auto rounded-full inset-y-0"
      />
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseInputToggle',
  props: {
    context: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.toggle__dot {
  right: 2px;
  @apply transition-all duration-300 ease-in-out rounded-full;
}
input:checked ~ .toggle__dot {
  @apply bg-grey-5;
  transform: translateX(-38px);
}

.toggle__line {
  @apply w-16 h-6 flex items-center justify-start border border-grey-2 text-grey-2 rounded-full;
}
input:checked ~ .toggle__line {
  @apply text-grey-5 border-grey-5 justify-end;
}
</style>
