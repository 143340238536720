<template>
  <label
    :for="context.id"
    :class="[context.classes.label, { 'opacity-100': inputHasValue }]"
  >
    {{ context.label }}
    <span v-if="showRequiredLabel"> ({{ $t('required') }}) </span>
    <span v-if="showOptionalLabel"> ({{ $t('optional') }}) </span>
  </label>
</template>

<script>
export default {
  name: 'BaseInputLabel',
  props: {
    context: {
      type: Object,
      required: true
    },
    showRequiredLabel: {
      type: Boolean,
      default: false
    },
    showOptionalLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputHasValue() {
      // 0 counts as input and label should be shown.
      return !['undefined', null, ''].includes(this.context.model);
    }
  }
};
</script>
