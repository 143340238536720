import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueFormulate from '@braid/vue-formulate';
import { setClientsLang } from '@/services/axiosClients';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = Object.freeze(locales(key));
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
});

// Function exposed to changed the i18n locale
window.seti18nLang = function(lang) {
  i18n.locale = lang;
  VueFormulate.setLocale(lang);

  setClientsLang(lang);

  document.querySelector('html').setAttribute('lang', lang);
};

export default i18n;
