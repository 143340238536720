<template>
  <transition name="fade" mode="out-in">
    <div
      v-if="message"
      role="alert"
      :class="[
        'my-4 p-4 bg-opacity-5 rounded flex justify-between',
        styles.bg,
        styles.text
      ]"
    >
      <div class="flex items-center">
        <font-awesome-icon
          :icon="message.icon || styles.icon"
          :spin="styles.loading"
          size="lg"
          :class="styles.text"
        />
        <div class="mx-4">
          <p class="font-bold" v-text="message.title" />
          <!-- eslint-disable-next-line -->
          <p class="text-sm leading-7 " v-html="message.text" />
        </div>
      </div>
      <button v-if="dismissable" @click="$emit('dismissed')">
        <font-awesome-icon :icon="['far', 'fa-times']" class="text-sm" />
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseIconMessage',
  props: {
    message: {
      type: Object,
      default: null
    },
    dismissable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    styles() {
      switch (this.message?.type) {
        case 'success':
          return {
            icon: ['fas', 'circle-check'],
            bg: 'bg-snackbar-success',
            text: 'text-snackbar-success'
          };
        case 'danger':
          return {
            icon: ['fas', 'circle-exclamation'],
            bg: 'bg-snackbar-danger',
            text: 'text-snackbar-danger'
          };
        case 'notification':
          return {
            icon: ['fas', 'circle-info'],
            bg: 'bg-snackbar-notification',
            text: 'text-snackbar-notification'
          };
        case 'loading':
          return {
            icon: ['far', 'spinner-third'],
            bg: 'bg-grey-1',
            text: 'text-grey-1',
            loading: true
          };
        default:
          return '';
      }
    }
  }
};
</script>
