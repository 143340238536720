<template functional>
  <div class="relative group inline-flex flex-col items-center">
    <font-awesome-icon :icon="['far', 'circle-info']" />
    <div
      class="absolute bottom-0 hidden group-hover:flex flex-col items-center mb-6"
    >
      <span
        class="tg-body-extra-small md:tg-body-small leading-tight z-10 px-2 py-1 text-white bg-black shadow-lg rounded whitespace-nowrap"
      >
        <slot />
      </span>
      <div class="w-3 h-3 -mt-2 rotate-45 bg-black" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTooltip'
};
</script>
