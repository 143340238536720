/* eslint-disable no-console */
import axios from 'axios';
import { getCookie } from '@/utils/helpers';

// Global axios defaults
axios.defaults.timeout = 30000;

// httpClient for user related authenticated routes
export const httpClient = axios.create({
  baseURL: process.env.VUE_APP_IAM_API_URL,
  withCredentials: true // For the cookie based auth, to avoid CORS
});

// array of all the clients defined above.
const clients = [httpClient];

httpClient.interceptors.request.use(request => {
  const csrftoken = getCookie('csrftoken');
  if (csrftoken) request.headers['X-CSRFToken'] = csrftoken;
  return request;
});

// common exported functions
export function setClientsLang(lang) {
  clients.forEach(client => {
    client.defaults.headers.common['Accept-Language'] = lang;
  });
}
