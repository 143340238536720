<template>
  <div
    :class="
      `formulate-input-element formulate-input-element--${context.type} relative`
    "
    :data-type="context.type"
    @keydown.esc.stop="onEsc"
  >
    <button
      type="button"
      v-bind="context.attributes"
      aria-label="show options"
      class="relative text-left"
      @click.stop="showOptions = !showOptions"
    >
      <template v-if="model.length">
        {{ model.length }}
        <span>
          {{
            model.length === 1 ? $t('selected_singular') : $t('selected_plural')
          }}
        </span>
      </template>
      <span v-else :aria-placeholder="context.placeholder">
        {{ context.placeholder || '' }}
      </span>
      <font-awesome-icon
        :icon="['far', 'fa-chevron-down']"
        class="fa-xs px-1 absolute right-0 top-1/2 transform -translate-y-1/2"
      />
    </button>
    <ul
      v-show="showOptions"
      tabindex="-1"
      aria-label="options"
      :aria-expanded="showOptions"
      class="text-base absolute max-h-130 w-full bg-white rounded-md overflow-y-auto shadow-card z-20"
    >
      <BaseRecursiveRender
        v-for="(option, index) in context.options"
        :key="`option_${index}`"
        tag="li"
        :item="option"
        recursive-key="children"
        :aria-label="option.label"
      >
        <template #render="{ item }">
          <button
            type="button"
            :title="item.label"
            :disabled="item.disabled"
            :class="[
              item.children ? 'font-semibold' : 'font-normal',
              { 'hover:bg-grey-4 focus:bg-grey-4': !item.disabled },
              'px-2 py-1.25 w-full text-left flex flex-row justify-between gap-2 text-sm'
            ]"
            @click.stop="selectItem(item)"
          >
            <span class="truncate flex-grow">{{ item.label || '' }}</span>
            <font-awesome-icon
              v-if="item.value && model.includes(parseInt(item.value))"
              :aria-checked="true"
              :icon="['fas', 'fa-check-circle']"
            />
          </button>
        </template>
      </BaseRecursiveRender>
    </ul>
  </div>
</template>

<script>
import union from 'lodash/union';
import difference from 'lodash/difference';

export default {
  name: 'BaseInputMultipleSelect',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showOptions: false,
      arrowCounter: -1
    };
  },
  computed: {
    model: {
      get() {
        return this.context.model;
      },
      set(val) {
        this.context.model = val;
      }
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(value) {
        if (!Array.isArray(value)) this.model = [];
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    getChildValues(children = []) {
      const values = [];
      children.forEach(child => {
        if (child.value) values.push(child.value);
        if (child.children) values.push(...this.getChildValues(child.children));
      });
      return values;
    },
    selectItem(item) {
      const childValues = this.getChildValues(item.children);

      const values = [...childValues];
      if (item.value) values.push(parseInt(item.value));

      const valuesExist = values.every(value => this.model.includes(value));
      this.model = valuesExist
        ? difference(this.model, values)
        : union(this.model, values);
      return values;
    },
    onEsc() {
      this.showOptions = false;
      this.context.blurHandler();
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) this.onEsc();
    }
  }
};
</script>
